<template lang="pug">
.om-wizard-bottom
  .container.h-100
    .d-flex.align-items-center.h-100
      om-button(ghost @click="goBack()" data-track="wizard-back") {{ $t('back') }}
      .ml-auto
        .d-flex.align-items-center
          template(v-if="!isRecommendationStep")
            om-button.ml-3(
              :disabled="!showNextButton || disableNextButton"
              :class="nextButtonClasses"
              primary
              @click="$emit('next')"
            ) {{ $t('next') }}
</template>

<script>
  import navigationMixin from '@/views/Wizard/navigation';
  import {
    RECOMMENDATION,
    NAVIGATED_ON_SELECT,
    GOALS,
    SIMILAR_TEMPLATES,
  } from '@/views/Wizard/flow';
  import sharedMixin from '@/views/Wizard/shared';
  import { track } from '@/services/xray';

  export default {
    mixins: [navigationMixin, sharedMixin],
    props: {
      disableNextButton: { type: Boolean, default: false },
    },
    computed: {
      browseClass() {
        return this.isRecommendationStep ? 'skip-wizard-recommendations' : 'skip-wizard-similar';
      },
      isRecommendationStep() {
        return [RECOMMENDATION, SIMILAR_TEMPLATES].includes(this.step);
      },
      showNextButton() {
        return !NAVIGATED_ON_SELECT.includes(this.step);
      },
      nextButtonClasses() {
        return { 'om-wizard-bottom__button--hide': !this.showNextButton };
      },
    },
    methods: {
      async finishWizard() {
        track('wizard-skip-selector');
        this.$store.dispatch('saveOnboardingStage', null);
        await this.updateWizardPreferences({ skip: true });
        const { userId } = this.$route.params;
        return this.$router.push({ name: 'templates', params: { userId, recLoader: false } });
      },
      goBack() {
        const isContinued = window.location?.search?.includes?.('continued=1') ?? false;
        const currentStepIndex = this.order.indexOf(this.step);
        const previousStep = this.order?.[currentStepIndex - 1] ?? this.order[0];

        if (!isContinued || previousStep === this.step) {
          this.$router.go(-1);
          return;
        }

        const urlObject = new URL(window.location.href);
        urlObject.searchParams.delete(previousStep);
        if (previousStep === GOALS) {
          urlObject.searchParams.delete('continued');
        }
        const path = `${urlObject.pathname}${urlObject.search}`.replace(this.step, previousStep);
        this.$emit('back', path);
        this.$router.replace({ path });
      },
    },
  };
</script>

<style lang="sass">
  .om-wizard-bottom
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    height: 3.5rem
    background-color: white
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1)
    color: #505763
    z-index: 10000

    &__button--hide
      opacity: 0 !important
      cursor: default !important
</style>
